<template>
    <div>
        <div class="upload-list" :style="{width: `${width}px`, height: `${height}px`}" v-for="(item, index) in uploadList" :key="index">
            <template v-if="item.status === 'finished'">
                <video :src="item.url"/>
                <div class="upload-list-cover" :style="{width: `${width}px`, height: `${height}px`}">
                    <Icon type="ios-eye-outline" @click.native="handleView(item.url)"></Icon>
                    <Icon type="ios-trash-outline" @click.native="handleRemove(index)"></Icon>
                </div>
            </template>
            <template v-else>
                <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
        </div>
        <Upload
                ref="upload"
                v-show="uploadList.length < size"
                :show-upload-list="false"
                :multiple="multiple"
                type="drag"
                action="//upload.qiniup.com/"
                :data="{token: upToken}"
                :accept="accept"
                :max-size="maxSize"
                :on-format-error="handleFormatError"
                :on-exceeded-size="handleMaxSize"
                :before-upload="handleBeforeUpload"
                :on-success="(res, file, fileList) => handleSuccess(res, file, fileList)"
                :style=uploadStyle
        >
            <div :style=uploadSlotStyle>
                <Icon type="ios-cloud-upload" size="32" style="color: #3399ff"></Icon>
            </div>
        </Upload>
        <video-preview ref="video-preview" :autoplay="true"></video-preview>
    </div>
</template>

<script>
    import {mapActions} from 'vuex';
    import {ToolResource} from "../request/store/ToolResource";

    export default {
        name: "el-file-upload",
        props: {
            cdns: {
                type: Boolean,
                default: () => false
            },
            width: {
                type: Number,
                default: 78
            },
            height: {
                type: Number,
                default: 53
            },
            multiple: {
                type: Boolean,
                default: false
            },
            accept: {
                type: String,
                default: 'video/mp4,video/3gp'
            },
            /**
             * 文件大小限制
             */
            maxSize: {
                type: Number,
                default: 5120
            },
            /**
             * 最大上传数量
             */
            size: {
                type: Number,
                default: 5
            },
            videos: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                upToken: '',
                uploadList: []
            }
        },
        mounted () {
            this.uploadList = this.$refs.upload.fileList;
            this.videos.filter(j => j !== '' && j !== undefined).forEach(j => {
                this.uploadList.push({
                    status: 'finished',
                    url: j,
                    showProgress: false
                })
            })
        },
        computed: {
            uploadStyle() {
                return `display: inline-block;width: ${this.width}px; height:${this.height}`;
            },
            uploadSlotStyle() {
                return `width: ${this.width}px;height:${this.height}px;line-height: ${this.height}px;`;
            }
        },
        methods: {
            ...mapActions([
                'qiniuToken'
            ]),
            handleSuccess(res, file, fileList) {
                let self = this;
                file.url = (self.cdns? this.$cdns: this.$cdn) + res.key;
                let fileName = file.name;
                let type = '';
                if (fileName.indexOf('.') !== -1) {
                    type = fileName.substring(fileName.lastIndexOf('.') +1, fileName.length);
                }
                ToolResource.save({
                    key: res.key,
                    name: file.name,
                    resType: `video/${type}`,
                    size: file.size,
                    url: file.url
                }).then(body => {})
                this.$emit('on-success', file.url);
            },
            handleFormatError(file) {
                this.$Notice.warning({
                    title: '文件格式错误',
                    desc: `文件格式不正确，仅支持 ${this.types.join(',')}`
                });
            },
            handleMaxSize(file) {
                this.$Notice.warning({
                    title: '文件超出大小限制',
                    desc: `文件不得大于 ${this.maxSize}KB`
                });
            },
            handleBeforeUpload() {
                let self = this;
                return new Promise((resolve, reject) => {
                    let check = self.uploadList.length < self.size;
                    if (!check) {
                        self.$Notice.warning({
                            title: '文件超出数量限制',
                            desc: `${this.size}个文件已上传`
                        });
                        reject();
                    }
                    self.qiniuToken().then(token => {
                        self.upToken = token;
                        resolve();
                    });
                })
            },
            handleView(path) {
                this.$refs['video-preview'].show(path);
            },
            handleRemove(index) {
                this.$refs.upload.fileList.splice(index, 1);
                this.$emit('on-remove', index);
            }
        }
    }
</script>

<style scoped lang="less">

</style>